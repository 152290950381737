import { Component, OnInit, Input } from '@angular/core';
import SwiperCore,{ SwiperOptions } from 'swiper';
import { SearchService } from '../../services/search.service'

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  slideData: any[] = [];
  hidden: boolean = false;

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    scrollbar: {
      el: '.swiper-scrollbar'
    },
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 3000,
    }
  };

  constructor(private _searchService: SearchService) { }

  ngOnInit(): void {
    this._searchService.getCarrousel().subscribe((res:any) => {
      this.slideData = res.carrousel
    })
  }

  @Input()
  set hiddenin(hidden) {
    if (typeof hidden === 'undefined') { return; }
    console.log('hidden')
    console.log(hidden)
    this.hidden = hidden;
  }

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }

  

}
