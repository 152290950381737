<div id="consulta" class="consulta bg-light-grey py-4" [hidden]="hidden">
  <div class="container">
    <div class="container-fluid">
      <div class="row py-lg-5">
        <div class="container">
          <div class="row mb-4">
            <div class="text-center">
              <h1 class="fw-medium h2 color-dark-grey text-center pb-2">
                Catálogo de productos<br />
              </h1>
            </div>
            <form (submit)="searchByText()">
              <div class="row">
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="w-100 form-control"
                    name="searchtext"
                    [(ngModel)]="searchModel.text"
                    placeholder="¿Qué estás buscando?"
                  />
                </div>
                <div class="col-sm-3">
                  <button
                    class="btn btn-primary w-100 btn-buscar"
                    type="submit"
                    show="resultado"
                    hide="consulta"
                  >
                    <span class="icono icono-se-light ms-2"></span> Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <form (submit)="searchAdvanced()">
            <div>
              <h1 class="h3 color-dark-grey text-center pb-2">
                Búsqueda avanzada
              </h1>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-4">
                Familia

                <ng-select
                  [items]="familyOptions"
                  [multiple]="true"
                  [(ngModel)]="familyOption"
                  name="familyOption"
                >
                </ng-select>
              </div>
              <div class="col-lg-4">
                <label for="designacionInput">Designación</label>
                <input
                  type="text"
                  (click)="hiddenAllCheckboxes()"
                  [(ngModel)]="searchModel.designation"
                  class="form-control"
                  name="designation"
                  id="designacionInput"
                />
              </div>
              <div class="col-lg-4">
                <label for="observacionesInput">Observaciones</label>
                <input
                  type="text"
                  name="observations"
                  (click)="hiddenAllCheckboxes()"
                  [(ngModel)]="searchModel.observations"
                  class="form-control"
                  id="observacionesInput"
                />
              </div>
            </div>
            <div class="row align-items-end">
              <!--Aro interior-->
              <div class="col-lg-2">
                Aro interior
                <ng-select
                  [items]="arointOptions"
                  [multiple]="true"
                  [(ngModel)]="arointOption"
                  name="arointOption"
                >
                </ng-select>
              </div>
              <!--Aro exterior-->
              <div class="col-lg-2">
                Aro exterior
                <ng-select
                  [items]="aroextOptions"
                  [multiple]="true"
                  [(ngModel)]="aroextOption"
                  name="aroextOption"
                >
                </ng-select>
              </div>
              <!--Sello por lado-->
              <div class="col-lg-3">
                Sello por lado
                <ng-select
                  [items]="sellosOptions"
                  [multiple]="true"
                  [(ngModel)]="sellosOption"
                  name="sellosOption"
                >
                </ng-select>
              </div>
              <!--Dimensiones-->
              <div class="col-lg-2">
                <label for="observacionesInput">Dimension</label>
                <input
                  type="text"
                  (click)="hiddenAllCheckboxes()"
                  [(ngModel)]="searchModel.dimentions"
                  class="form-control"
                  name="dimentions"
                  id="observacionesInput"
                  value="0"
                />
              </div>
              <div class="col-lg-3">
                <label for="dimension-unit-select">Unidad de medida</label>
                <select
                  (click)="hiddenAllCheckboxes()"
                  class="form-select"
                  name="unit"
                  id="dimension-unit-select"
                  [(ngModel)]="searchModel.unit"
                >
                  <option>d[mm]</option>
                  <option>d[inch]</option>
                  <option>D[mm]</option>
                  <option>Bi[mm]</option>
                  <option>Be[mm]</option>
                </select>
              </div>
              <!--Boton-->
              <div class="w-100">
                <button
                  type="submit"
                  class="btn btn-primary w-100 btn-buscar mt-4"
                  show="resultado"
                  hide="consulta"
                >
                  <span class="icono icono-se-light me-1"></span>
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
