import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BlogService } from 'src/services/blog.service';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';

const chunkSize = 9;
const LOCAL_STORAGE_KEY = 'blog-items';
@Component({
  selector: 'app-blog-full',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogFullComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    private _service: BlogService,
    private router: Router
  ) {}

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  items: any[] = [];
  page = 1;
  retrieved = 0;
  total;
  loading: boolean;
  searchQuery: string;
  loadingSearch: boolean = false;
  private searchSubject = new Subject<string>();
  getPaginationOptions() {
    return {
      limit: chunkSize,
      offset: (this.page - 1) * chunkSize,
    };
  }
  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  getDocs() {
    this.loading = true;
    this._service.get(this.getPaginationOptions()).subscribe(
      (res) => {
        res.docs.forEach((doc) => {
          const existingIndex = this.items.findIndex(
            (item) => item._id === doc._id
          );
          if (existingIndex !== -1) {
            this.items[existingIndex] = doc;
          } else {
            this.items.push(doc);
          }
        });

        this.total = res.totalDocs;
        this.retrieved += chunkSize;
        this.loading = false;

        sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.items));
      },
      () => {}
    );
  }
  ngOnInit(): void {
    this.getDocs();
    this.scrollTop();
    const cachedItems = sessionStorage.getItem(LOCAL_STORAGE_KEY);
    if (cachedItems) {
      this.items = JSON.parse(cachedItems);
    }
    this.searchSubject
      .pipe(
        debounceTime(1400),
        distinctUntilChanged(),
        tap(() => (this.loading = true)),
        switchMap((query) => this._service.search({ q: query })),
        finalize(() => (this.loading = false))
      )
      .subscribe((res) => {
        this.items = res.docs;

        this.total = res.totalDocs;
        this.retrieved += chunkSize;
        this.loading = false;
      });
  }

  onSearchInputChange(): void {
    this.searchSubject.next(this.searchQuery);
  }
  isMoreDocsAvailable() {
    return this.retrieved < this.total;
  }
  getMoreDocs() {
    if (this.isMoreDocsAvailable()) {
      ++this.page;
      this.getDocs();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (windowHeight + scrollTop >= documentHeight - 250) {
      this.getMoreDocs();
    }
  }
}
