import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();

  hiddenFamily: boolean = true;
  hiddenDesignation: boolean = true;
  hiddenAroInt: boolean = true;
  hiddenAroExt: boolean = true;
  hiddenSellos: boolean = true;

  familys: any[] = [];
  designations: any[] = [];
  aroInt: any[] = [];
  aroExt: any[] = [];
  sellos: any[] = [];

  familysSelected: any = {};
  designationsSelected: any = {};
  aroIntSelected: any = {};
  aroExtSelected: any = {};
  sellosSelected: any = {};

  products: any[] = [];
  productsAll: any[] = [];
  titleText: string = '';
  visible: boolean = true;

  page: number = 1;
  pageSize: number = 20;
  total: number = 0;
  filters: any = {};
  searchModel: any = {};

  closeResult = '';

  constructor(
    private _searchService: SearchService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    console.log('result');
  }

  @Input()
  set productsin(products) {
    if (!products) {
      return;
    }
    this.products = products;
    this.productsAll = products;
    console.log('set products');
    console.log(products);

    this.page = 1;
  }

  @Input()
  set hiddenin(hidden) {
    if (typeof hidden === 'undefined') {
      return;
    }
    console.log('hidden');
    console.log(hidden);
    this.visible = !hidden;

    this.clearFilters();
  }

  @Input()
  set titlein(title) {
    if (!title) {
      return;
    }
    this.titleText = title;
  }

  @Input()
  set totalin(total) {
    if (!total) {
      return;
    }
    console.log('total', total);
    this.total = total;
  }

  @Input()
  set searchModelIn(searchModel) {
    console.log('searchModel', searchModel);
    if (!searchModel) {
      return;
    }
    console.log('searchModel', searchModel);
    this.searchModel = searchModel;
  }

  @Input()
  set filtersin(filters) {
    if (!filters) {
      return;
    }

    console.log('filtersin');
    console.log(filters);
    this.filters = filters;

    if (this.filters) {
      this.familys = this.filters.family
        ? this.filters.family.filter(this.filterEmpty)
        : [];
      this.designations = this.filters.designations
        ? this.filters.designations.filter(this.filterEmpty)
        : [];
      this.aroInt = this.filters.arointeriors
        ? this.filters.arointeriors.filter(this.filterEmpty)
        : [];
      this.aroExt = this.filters.aroexteriors
        ? this.filters.aroexteriors.filter(this.filterEmpty)
        : [];
      this.sellos = this.filters.sellos
        ? this.filters.sellos.filter(this.filterEmpty)
        : [];
    }
  }

  goBackToSearch() {
    this.goBack.emit('');
  }

  apply() {
    this.hiddenAllCheckboxes();

    const copyModel = { ...this.searchModel };

    if (!this.verifSelecteds(this.familysSelected))
      copyModel.family = this.familysSelected;
    if (!this.verifSelecteds(this.designationsSelected))
      copyModel.designation = this.designationsSelected;
    if (!this.verifSelecteds(this.aroIntSelected))
      copyModel.aroint = this.aroIntSelected;
    if (!this.verifSelecteds(this.aroExtSelected))
      copyModel.aroext = this.aroExtSelected;
    if (!this.verifSelecteds(this.sellosSelected))
      copyModel.sellos = this.sellosSelected;

    copyModel.filters = true;

    this._searchService.advanced(copyModel, this.page, 20).subscribe((res) => {
      this.total = res.products.totalDocs;
      this.products = res.products.docs;
    });
  }

  filterEmpty(value) {
    if (!value) return false;
    else return true;
  }

  pageChange() {
    console.log('page change', this.page);
    this.apply();
  }

  verifSelecteds(obj) {
    var flagEmpty = true;
    Object.keys(obj).forEach((elm, index) => {
      if (obj[elm]) flagEmpty = false;
    });
    return flagEmpty;
  }

  selectBox(option) {
    const boxes = [
      this.hiddenFamily,
      this.hiddenDesignation,
      this.hiddenAroInt,
      this.hiddenAroExt,
      this.hiddenSellos,
    ];

    for (let index = 0; index < boxes.length; index++) {
      if (index + 1 == option) {
        boxes[index] = !boxes[index];
      } else {
        boxes[index] = true;
      }
    }

    this.hiddenFamily = boxes[0];
    this.hiddenDesignation = boxes[1];
    this.hiddenAroInt = boxes[2];
    this.hiddenAroExt = boxes[3];
    this.hiddenSellos = boxes[4];

    console.log(boxes);
  }

  hiddenAllCheckboxes() {
    this.hiddenFamily = true;
    this.hiddenDesignation = true;
    this.hiddenAroInt = true;
    this.hiddenAroExt = true;
    this.hiddenSellos = true;
  }

  openModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clearFilters() {
    this.familysSelected = {};
    this.designationsSelected = {};
    this.aroIntSelected = {};
    this.aroExtSelected = {};
    this.sellosSelected = {};
    this.titleText = '';
  }
}
