<div id="resultado" [hidden]="visible" class="resultado bg-medium-grey py-4">
  <div class="container-fluid">
    <div class="row pb-lg-2">
      <div class="container">
        <div class="row">
          <p class="fw-medium text-white">Resultado</p>
        </div>

        <!--Detalle de resultado -->
        <div class="container">
          <div class="row mb-4">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="row">
                    <div class="card">
                      <div class="card-body">
                        <h2 class="card-title h4 fw-medium color-dark-grey">
                          {{ titleText }}
                        </h2>
                        <p class="card-text">
                          Se encontraron {{ total }} resultados para tu
                          búsqueda.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="total >= 2">
          <div>
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="goBackToSearch()"
              show="consulta"
              hide="resultado"
            >
              <span class="icono icono-rese-light me-2"></span>Volver a buscar
            </button>
          </div>
        </div>

        <!--Filtrar responsive-->
        <div class="row justify-content-center py-4 d-lg-none d-block">
          <button
            type="button"
            class="btn btn-secondary w-100"
            (click)="openModal(content)"
          >
            <span class="icono icono-fil-primary me-2"></span>Filtrar
          </button>
        </div>

        <!--header tabla-->
        <div class="container">
          <div class="row d-none d-lg-block">
            <div class="container py-4">
              <div class="row">
                <div class="">
                  <h1 class="fw-medium color-light h4 text-center pb-4 mb-0">
                    Filtrar
                  </h1>
                </div>
              </div>
              <div class="row position-relative mb-5">
                <div class="position-absolute top-0 start-0">
                  <div class="header-table d-flex flex-lg-row flex-md-column">
                    <!--Columna 1-->
                    <div class="w-100 d-flex">
                      <div class="w-100 mx-2">
                        <div class="d-flex">
                          <div class="w-100">
                            <div class="multiselect w-100">
                              <div
                                class="selectBox position-relative"
                                (click)="selectBox(1)"
                                target="checkboxesFamilia"
                              >
                                <select
                                  class="form-select color-primary fw-medium"
                                >
                                  <option>Familia</option>
                                </select>
                                <div class="overSelect position-absolute"></div>
                              </div>
                              <div
                                id="checkboxesFamilia"
                                [hidden]="hiddenFamily"
                                class="checkboxes checkboxes-filtrar bg-light"
                              >
                                <div class="checkboxes-list">
                                  <div
                                    class="pb-2"
                                    *ngFor="let item of familys; let i = index"
                                  >
                                    <label
                                      for="familia-{{ i + 1 }}"
                                      class="py-1 pe-2"
                                    >
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="familysSelected[item]"
                                        id="familia-{{ i + 1 }}"
                                        class="me-2"
                                      />{{ item }}
                                      <span class="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                                <div class="btn-aplicar-box">
                                  <button
                                    (click)="apply()"
                                    class="btn btn-primary w-100"
                                  >
                                    Aplicar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mx-2">
                        <div class="d-flex">
                          <div class="w-100">
                            <div class="multiselect w-100">
                              <div
                                class="selectBox position-relative"
                                (click)="selectBox(2)"
                                target="checkboxesDesignacion"
                              >
                                <select
                                  class="form-select color-primary fw-medium"
                                >
                                  <option>Designación</option>
                                </select>
                                <div class="overSelect position-absolute"></div>
                              </div>
                              <div
                                id="checkboxesDesignacion"
                                [hidden]="hiddenDesignation"
                                class="checkboxes checkboxes-filtrar bg-light"
                              >
                                <div class="checkboxes-list">
                                  <div
                                    class="pb-2"
                                    *ngFor="
                                      let item of designations;
                                      let i = index
                                    "
                                  >
                                    <label
                                      for="designacion-{{ i + 1 }}"
                                      class="py-1 pe-2"
                                    >
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="designationsSelected[item]"
                                        id="designacion-{{ i + 1 }}"
                                        class="me-2"
                                      />{{ item }}
                                      <span class="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                                <div class="btn-aplicar-box">
                                  <button
                                    (click)="apply()"
                                    class="btn btn-primary w-100"
                                  >
                                    Aplicar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--<div class="w-100 mx-2">
                              <p class="fw-medium color-dark-grey">Diseño</p>
                            </div>-->

                      <div class="w-100 mx-2">
                        <div class="d-flex flex-lg-column">
                          <!--aro interior-->
                          <div class="w-100 mb-2">
                            <div class="multiselect w-100">
                              <div
                                class="selectBox position-relative"
                                (click)="selectBox(3)"
                                target="filtroAroInterior"
                              >
                                <select
                                  class="form-select fw-medium color-primary"
                                >
                                  <option>Aro interior</option>
                                </select>
                                <div class="overSelect position-absolute"></div>
                              </div>
                              <div
                                id="filtroAroInterior"
                                [hidden]="hiddenAroInt"
                                class="checkboxes checkboxes-filtrar bg-light"
                              >
                                <div class="checkboxes-list">
                                  <div
                                    class="pb-2"
                                    *ngFor="let item of aroInt; let i = index"
                                  >
                                    <label
                                      for="filtroAroInterior-{{ i + 1 }}"
                                      class="py-1 pe-2"
                                    >
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="aroIntSelected[item]"
                                        id="filtroAroInterior-{{ i + 1 }}"
                                        class="me-2"
                                      />{{ item }}
                                      <span class="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                                <div class="btn-aplicar-box">
                                  <button
                                    (click)="apply()"
                                    class="btn btn-primary w-100"
                                  >
                                    Aplicar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mx-2">
                        <div class="d-flex flex-lg-column">
                          <!--aro exterior-->
                          <div class="w-100 mb-2">
                            <div class="multiselect w-100">
                              <div
                                class="selectBox position-relative"
                                (click)="selectBox(4)"
                                target="filtroAroExterior"
                              >
                                <select
                                  class="form-select fw-medium color-primary"
                                >
                                  <option>Aro exterior</option>
                                </select>
                                <div class="overSelect position-absolute"></div>
                              </div>
                              <div
                                id="filtroAroExterior"
                                [hidden]="hiddenAroExt"
                                class="checkboxes checkboxes-filtrar bg-light"
                              >
                                <div class="checkboxes-list">
                                  <div
                                    class="pb-2"
                                    *ngFor="let item of aroExt; let i = index"
                                  >
                                    <label
                                      for="filtroAroExterior-{{ i + 1 }}"
                                      class="py-1 pe-2"
                                    >
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="aroExtSelected[item]"
                                        id="filtroAroExterior-{{ i + 1 }}"
                                        class="me-2"
                                      />{{ item }}
                                      <span class="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                                <div class="btn-aplicar-box">
                                  <button
                                    (click)="apply()"
                                    class="btn btn-primary w-100"
                                  >
                                    Aplicar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mx-2">
                        <div class="d-flex flex-lg-column">
                          <!--sello por lado-->
                          <div class="w-100">
                            <div class="multiselect w-100">
                              <div
                                class="selectBox position-relative"
                                (click)="selectBox(5)"
                                target="filtroSelloPorLado"
                              >
                                <select
                                  class="form-select fw-medium color-primary"
                                >
                                  <option>Sello por lado</option>
                                </select>
                                <div class="overSelect position-absolute"></div>
                              </div>
                              <div
                                id="filtroSelloPorLado"
                                [hidden]="hiddenSellos"
                                class="checkboxes checkboxes-filtrar bg-light"
                              >
                                <div class="checkboxes-list">
                                  <div
                                    class="pb-2"
                                    *ngFor="let item of sellos; let i = index"
                                  >
                                    <label
                                      for="filtroSelloPorLado-{{ i + 1 }}"
                                      class="py-1 pe-2"
                                    >
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="sellosSelected[item]"
                                        id="filtroSelloPorLado-{{ i + 1 }}"
                                        class="me-2"
                                      />{{ item }}
                                      <span class="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                                <div class="btn-aplicar-box">
                                  <button
                                    (click)="apply()"
                                    class="btn btn-primary w-100"
                                  >
                                    Aplicar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--<div class="w-100 mx-2">
                              <p class="fw-medium color-dark-grey px-2">Dimensiones</p>
                            </div>-->
                    </div>
                    <!--Columna 2-->

                    <!--<div class="w-100 d-flex">
                            <div class="w-100 mx-2">
                              <p class="fw-medium color-dark-grey px-2">Observaciones</p>
                            </div>
                            <div class="w-100 mx-2">
                              <p class="fw-medium color-dark-grey px-2">Otras designaciones</p>
                            </div>
                          </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Fila de info-->
        <div class="container">
          <div class="row mx-2" *ngFor="let product of products">
            <div class="container">
              <div class="row">
                <div class="fila-info d-flex flex-lg-row flex-column bg-light">
                  <!--Columna 1: Familia, Designación, Diseño, Aro interior, Aro exterior, Sello por lado y Dimensiones-->
                  <div
                    class="w-100 d-flex flex-lg-row flex-column align-items-stretch"
                  >
                    <div class="w-100 mx-2 py-2">
                      <div class="d-flex flex-column">
                        <div class="w-100">
                          <p class="fw-medium color-primary mb-0">Familia</p>
                        </div>
                        <div class="w-100">
                          <p class="color-dark-grey mb-0">
                            {{ product.family }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="w-100 mx-2 py-2">
                      <div class="d-flex flex-column">
                        <div class="w-100">
                          <p class="fw-medium color-primary mb-0">
                            Designación
                          </p>
                        </div>
                        <div class="w-100">
                          <p class="color-dark-grey mb-0">
                            {{ product.designation }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="w-100 mx-2 py-2">
                      <div class="d-flex flex-column">
                        <div class="w-100">
                          <p class="fw-medium color-primary mb-0">Diseño</p>
                        </div>
                        <div class="w-100">
                          <picture class="w-100">
                            <source
                              src="/assets/imgs/{{ product.imagepath }}"
                              media="(max-width: 767px) and (orientation: portrait)"
                            />
                            <img
                              class="imgDB"
                              src="/assets/imgs/{{ product.imagepath }}"
                              alt=""
                            />
                          </picture>
                        </div>
                      </div>
                    </div>

                    <div class="w-100 mx-2 py-2">
                      <div class="d-flex flex-column">
                        <div class="w-100">
                          <p class="fw-medium color-primary mb-0">
                            Aro interior
                          </p>
                        </div>
                        <div class="w-100">
                          <p class="color-dark-grey mb-0">
                            {{ product.arointerior }}
                          </p>
                        </div>

                        <div class="w-100">
                          <p class="fw-medium color-primary mb-0">
                            Aro exterior
                          </p>
                        </div>
                        <div class="w-100">
                          <p class="color-dark-grey mb-0">
                            {{ product.aroexterior }}
                          </p>
                        </div>

                        <div class="w-100">
                          <p class="fw-medium color-primary mb-0">
                            Sello por lado
                          </p>
                        </div>
                        <div class="w-100">
                          <p class="color-dark-grey mb-0">
                            {{ product.sellos }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 d-flex flex-column align-items-stretch">
                    <div class="w-100 d-flex flex-lg-row align-items-stretch">
                      <div class="w-100 mx-2 py-2">
                        <div class="d-flex flex-column">
                          <div class="w-100">
                            <p class="fw-medium color-primary mb-0">
                              Dimensiones
                            </p>
                          </div>

                          <div
                            class="d-flex flex-lg-row flex-md-row justify-content-md-between"
                          >
                            <div class="d-flex flex-row w-100">
                              <div class="d-flex flex-lg-row flex-column w-100">
                                <div class="w-100">
                                  <div class="d-flex flex-column">
                                    <div class="w-100">
                                      <p class="fw-medium color-primary mb-0">
                                        d [mm]
                                      </p>
                                    </div>
                                    <div class="w-100">
                                      <p class="color-dark-grey mb-0">
                                        {{
                                          product.dimentions
                                            ? product.dimentions.dmm
                                            : "-" || "-"
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="w-100">
                                  <div class="d-flex flex-column">
                                    <div class="w-100">
                                      <p class="fw-medium color-primary mb-0">
                                        d [inch]
                                      </p>
                                    </div>
                                    <div class="w-100">
                                      <p class="color-dark-grey mb-0">
                                        {{
                                          product.dimentions
                                            ? product.dimentions.dinch
                                            : "-" || "-"
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="w-100">
                                  <div class="d-flex flex-column">
                                    <div class="w-100">
                                      <p class="fw-medium color-primary mb-0">
                                        D [mm]
                                      </p>
                                    </div>
                                    <div class="w-100">
                                      <p class="color-dark-grey mb-0">
                                        {{
                                          product.dimentions
                                            ? product.dimentions.dmm2
                                            : "-" || "-"
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="d-flex flex-lg-row flex-column w-100">
                              <div class="w-100">
                                <div class="d-flex flex-column">
                                  <div class="w-100">
                                    <p class="fw-medium color-primary mb-0">
                                      Bi [mm]
                                    </p>
                                  </div>
                                  <div class="w-100">
                                    <p class="color-dark-grey mb-0">
                                      {{
                                        product.dimentions
                                          ? product.dimentions.bimm
                                          : "-" || "-"
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="w-100">
                                <div class="d-flex flex-column">
                                  <div class="w-100">
                                    <p class="fw-medium color-primary mb-0">
                                      Be [mm]
                                    </p>
                                  </div>
                                  <div class="w-100">
                                    <p class="color-dark-grey mb-0">
                                      {{
                                        product.dimentions
                                          ? product.dimentions.bemm
                                          : "-" || "-"
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Columna 2: Observaciones y designaciones-->
                    <div
                      class="w-100 d-flex flex-lg-row flex-column align-items-stretch"
                    >
                      <div class="w-100 mx-2 py-2">
                        <div class="d-flex flex-column">
                          <div class="w-100">
                            <p class="fw-medium color-primary mb-0">
                              Observaciones
                            </p>
                          </div>
                          <div class="w-100">
                            <p class="color-dark-grey mb-0">
                              {{ product.observations }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mx-2 py-2">
                        <div class="d-flex flex-column">
                          <div class="w-100">
                            <p class="fw-medium color-primary mb-0">
                              Otras designaciones
                            </p>
                          </div>
                          <div class="w-100">
                            <p class="color-dark-grey mb-0">
                              {{ product.otherdesignations }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--pagination-->
        <div class="d-flex justify-content-center my-5">
          <ngb-pagination
            class="float-lg-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="5"
            [collectionSize]="total"
            (pageChange)="pageChange()"
          ></ngb-pagination>
        </div>

        <!--volver a buscar-->
        <div class="row">
          <div>
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="goBackToSearch()"
              show="consulta"
              hide="resultado"
            >
              <span class="icono icono-rese-light me-2"></span>Volver a buscar
            </button>
          </div>
        </div>

        <!--Modal-->
        <ng-template #content let-modal>
          <div class="modal-header bg-primary">
            <h5 class="modal-title color-light">Filtrar</h5>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
              data-bs-dismiss="modal"
              (click)="closeModal()"
              style="
                position: absolute;
                right: 10px;

                font-size: 2em;
                background-color: transparent;
                border: none;
                color: white;
              "
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body bg-primary">
            <div class="d-flex flex-column">
              <!--Familia-->
              <div class="w-100 mb-2">
                <div class="multiselect w-100">
                  <div
                    class="selectBox position-relative"
                    (click)="selectBox(1, true)"
                    target="checkboxesFamiliaResponsive"
                  >
                    <select class="form-select color-primary fw-medium">
                      <option>Familia</option>
                    </select>
                    <div class="overSelect position-absolute"></div>
                  </div>
                  <div
                    id="checkboxesFamiliaResponsive"
                    [hidden]="hiddenFamily"
                    class="checkboxes bg-light"
                  >
                    <div class="checkboxes-list">
                      <div
                        class="pb-2"
                        *ngFor="let item of familys; let i = index"
                      >
                        <label for="familia-{{ i + 1 }}" class="py-1 pe-2">
                          <input
                            type="checkbox"
                            [(ngModel)]="familysSelected[item]"
                            id="familia-{{ i + 1 }}"
                            class="me-2"
                          />{{ item }}
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Designacion-->
              <div class="w-100 mb-2">
                <div class="multiselect w-100">
                  <div
                    class="selectBox position-relative"
                    (click)="selectBox(2, true)"
                    target="checkboxesDesignacionResponsive"
                  >
                    <select class="form-select color-primary fw-medium">
                      <option>Designación</option>
                    </select>
                    <div class="overSelect position-absolute"></div>
                  </div>
                  <div
                    id="checkboxesDesignacionResponsive"
                    [hidden]="hiddenDesignation"
                    class="checkboxes bg-light"
                  >
                    <div class="checkboxes-list">
                      <div
                        class="pb-2"
                        *ngFor="let item of designations; let i = index"
                      >
                        <label for="designacion-{{ i + 1 }}" class="py-1 pe-2">
                          <input
                            type="checkbox"
                            [(ngModel)]="designationsSelected[item]"
                            id="designacion-{{ i + 1 }}"
                            class="me-2"
                          />{{ item }}
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--aro interior-->
              <div class="w-100 mb-2">
                <div class="multiselect w-100">
                  <div
                    class="selectBox position-relative"
                    (click)="selectBox(3, true)"
                    target="filtroAroInteriorResponsive"
                  >
                    <select class="form-select fw-medium color-primary">
                      <option>Aro interior</option>
                    </select>
                    <div class="overSelect position-absolute"></div>
                  </div>
                  <div
                    id="filtroAroInteriorResponsive"
                    [hidden]="hiddenAroInt"
                    class="checkboxes bg-light"
                  >
                    <div class="checkboxes-list">
                      <div
                        class="pb-2"
                        *ngFor="let item of aroInt; let i = index"
                      >
                        <label
                          for="filtroAroInterior-{{ i + 1 }}"
                          class="py-1 pe-2"
                        >
                          <input
                            type="checkbox"
                            [(ngModel)]="aroIntSelected[item]"
                            id="filtroAroInterior-{{ i + 1 }}"
                            class="me-2"
                          />{{ item }}
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--aro exterior-->
              <div class="w-100 mb-2">
                <div class="multiselect w-100">
                  <div
                    class="selectBox position-relative"
                    (click)="selectBox(4, true)"
                    target="filtroAroExteriorResponsive"
                  >
                    <select class="form-select fw-medium color-primary">
                      <option>Aro exterior</option>
                    </select>
                    <div class="overSelect position-absolute"></div>
                  </div>
                  <div
                    id="filtroAroExteriorResponsive"
                    [hidden]="hiddenAroExt"
                    class="checkboxes bg-light"
                  >
                    <div class="checkboxes-list">
                      <div
                        class="pb-2"
                        *ngFor="let item of aroExt; let i = index"
                      >
                        <label
                          for="filtroAroExterior-{{ i + 1 }}"
                          class="py-1 pe-2"
                        >
                          <input
                            type="checkbox"
                            [(ngModel)]="aroExtSelected[item]"
                            id="filtroAroExterior-{{ i + 1 }}"
                            class="me-2"
                          />{{ item }}
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--sello por lado-->
              <div class="w-100">
                <div class="multiselect w-100">
                  <div
                    class="selectBox position-relative"
                    (click)="selectBox(5, true)"
                    target="filtroSelloPorLadoResponsive"
                  >
                    <select class="form-select fw-medium color-primary">
                      <option>Sello por lado</option>
                    </select>
                    <div class="overSelect position-absolute"></div>
                  </div>
                  <div
                    id="filtroSelloPorLadoResponsive"
                    [hidden]="hiddenSellos"
                    class="checkboxes bg-light"
                  >
                    <div class="checkboxes-list">
                      <div
                        class="pb-2"
                        *ngFor="let item of sellos; let i = index"
                      >
                        <label
                          for="filtroSelloPorLado-{{ i + 1 }}"
                          class="py-1 pe-2"
                        >
                          <input
                            type="checkbox"
                            [(ngModel)]="sellosSelected[item]"
                            id="filtroSelloPorLado-{{ i + 1 }}"
                            class="me-2"
                          />{{ item }}
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer bg-primary">
            <div class="d-flex flex-column w-100">
              <div class="w-100">
                <button
                  class="btn btn-secondary w-100"
                  (click)="apply(); modal.close('Save click')"
                >
                  Aplicar
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
