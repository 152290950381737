import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubscriptionsService } from 'src/services/subscriptions.service';

const SESSION_LAST_SUBMIT_KEY = 'last-newsletter-modal-submit';
@Component({
  templateUrl: './landing-modal-soporte.component.html',
  styleUrls: ['./landing-modal-soporte.component.scss'],
  providers: [NgbCarouselConfig],
})
export class LandingModalSoporteComponent implements OnInit {
  headerImg: string = 'assets/img/skf-agro-newsletter-subscription.jpg';
  public form: FormGroup;
  private submitted: boolean = false;
  public rateLimitingAlert: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private _subscriptionsService: SubscriptionsService,
    private _thisModal: NgbActiveModal
  ) {
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  hasError(controlName: string, errorName: string) {
    const control = this.form.get(controlName);
    return (
      control && control.dirty && this.submitted && control.hasError(errorName)
    );
  }
  onSubmit() {
    this.submitted = true;
    const lastSubmitTime = parseInt(
      window.sessionStorage.getItem(SESSION_LAST_SUBMIT_KEY) || '0'
    );
    const currentTime = new Date().getTime();
    const timeSinceLastSubmit = currentTime - lastSubmitTime;

    const minTimeBetweenSubmits = 18 * 1000;

    if (timeSinceLastSubmit < minTimeBetweenSubmits) {
      this.startThrottlingProgress();
      return;
    }

    if (this.form.valid) {
      this._subscriptionsService
        .submitSubscriptionForm(this.form.value)
        .subscribe(
          () => {
            this._thisModal.close();
          },
          (rej) => {
            if (rej.status === 429) {
              window.sessionStorage.setItem(
                SESSION_LAST_SUBMIT_KEY,
                '' + new Date().getTime()
              );
              this.startThrottlingProgress();
            }
          }
        );
    }
  }
  progress: number = 0;

  startThrottlingProgress() {
    this.rateLimitingAlert = true;

    const currentTime = new Date().getTime();
    const lastSubmitTime = parseInt(
      window.sessionStorage.getItem(SESSION_LAST_SUBMIT_KEY) || '0'
    );
    const timeSinceLastSubmit = currentTime - lastSubmitTime;

    const minTimeBetweenSubmits = 18 * 1000;
    const timeRemaining = Math.max(
      0,
      minTimeBetweenSubmits - timeSinceLastSubmit
    );

    const totalSeconds = timeRemaining / 1000;
    const increment = 1;
    const interval = (totalSeconds * 1000) / (100 / increment);

    const progressInterval = setInterval(() => {
      if (this.progress < 100) {
        this.progress += increment;
      } else {
        this.rateLimitingAlert = false;
        clearInterval(progressInterval);
        this.onSubmit();
      }
    }, interval);
  }
  closeModal() {
    this._thisModal.close();
  }
  ngOnInit(): void {}
}
