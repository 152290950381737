<footer>
  <div class="footer-1 bg-light py-2">
    <div class="container-fluid">
      <div class="row">
        <div class="container">
          <div class="row">
            <div
              class="d-flex flex-sm-row flex-column justify-content-sm-between align-items-center"
            >
              <div>
                <a
                  href="https://www.skf.com/ar/"
                  target="_blank"
                  class="color-dark-grey d-inline-block py-2 px-2 footer-link"
                  ><div class="d-flex flex-row align-items-center">
                    <span
                      class="footer-icon icono-we-primary d-inline-block me-1"
                    ></span
                    ><span> www.skf.com.ar</span>
                  </div></a
                >
              </div>
              <div>
                <a
                  href="https://www.facebook.com/SKFLAM/"
                  target="_blank"
                  class="color-dark-grey d-inline-block py-2 px-2 footer-link"
                  ><div class="d-flex flex-row align-items-center">
                    <span
                      class="footer-icon icono-fb-primary d-inline-block me-1"
                    ></span>
                    <span> SKFLAM</span>
                  </div></a
                >
              </div>
              <div>
                <a
                  href="https://www.instagram.com/skfgroup/"
                  target="_blank"
                  class="color-dark-grey d-inline-block py-2 px-2 footer-link"
                  ><div class="d-flex flex-row align-items-center">
                    <span
                      class="footer-icon icono-ig-primary d-inline-block me-1"
                    ></span
                    ><span> skfgroup</span>
                  </div></a
                >
              </div>
              <div>
                <a
                  href="https://www.youtube.com/c/SKFGroup"
                  target="_blank"
                  class="color-dark-grey d-inline-block py-2 px-2 footer-link"
                  ><div class="d-flex flex-row align-items-center">
                    <span
                      class="footer-icon icono-yt-primary d-inline-block me-1"
                    ></span
                    ><span> SKFgroup</span>
                  </div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-2 bg-primary py-2">
    <div class="container-fluid">
      <div class="row">
        <div class="container">
          <div class="row">
            <div
              class="d-flex flex-sm-row flex-column justify-content-sm-center align-items-center"
            >
              <div>
                <a
                  href="index.html"
                  target="_blank"
                  class="color-light d-inline-block py-2 footer-link footer-link-light text-uppercase"
                  ><div class="d-flex flex-row align-items-center">
                    <span
                      class="footer-icon icono-skf-light d-inline-block me-1"
                    ></span
                    ><span
                      class="mx-2 separador d-none d-sm-inline-block"
                    ></span></div
                ></a>
              </div>
              <div>
                <a
                  href="#"
                  target="_blank"
                  class="color-light d-inline-block py-2 footer-link footer-link-light text-uppercase"
                  ><div class="d-flex flex-row align-items-center">
                    <span> Derecho de autor</span
                    ><span
                      class="mx-2 separador d-none d-sm-inline-block"
                    ></span></div
                ></a>
              </div>
              <div>
                <a
                  href="#"
                  target="_blank"
                  class="color-light d-inline-block py-2 footer-link footer-link-light text-uppercase"
                  ><div class="d-flex flex-row align-items-center">
                    <span> Términos y condiciones</span
                    ><span
                      class="mx-2 separador d-none d-sm-inline-block"
                    ></span></div
                ></a>
              </div>
              <div>
                <a
                  href="#"
                  target="_blank"
                  class="color-light d-inline-block py-2 footer-link footer-link-light text-uppercase"
                  ><div class="d-flex flex-row align-items-center">
                    <span> Administrador</span>
                  </div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
