<div class="container">
  <div class="pt-5 px-4">
    <h1>Tutoriales</h1>
  </div>
  <div class="container">
    <div class="row my-4">
      <div class="col-lg-6" *ngFor="let tutorial of tutorials">
        <iframe
          class="tutorial-iframe p-1"
          width="100%"
          height="300"
          [src]="tutorial.src"
          [title]="tutorial.title"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</div>
