import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingModalSoporteComponent } from './modals/landing-modal-soporte.component';
import { GlobalEventService } from 'src/services/global-event-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'skf-front';
  constructor(
    private modalService: NgbModal,
    private globalEventService: GlobalEventService
  ) {}
  ngOnInit() {
    if (!localStorage.getItem('landing-modal-soporte-on-init-showed')) {
      this.openModal();
      localStorage.setItem('landing-modal-soporte-on-init-showed', 'true');
    }
    this.globalEventService.globalEventEmitter.subscribe((e) => {
      if ((e.type = 'OPEN_NEWSLETTER_SUBSCRIBE_MODAL')) {
        this.openModal();
      }
    });
  }
  openModal() {
    const modalRef = this.modalService.open(LandingModalSoporteComponent, {
      size: 'md',
    });
  }
}
