import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  products: any = [];
  inputtext: string = '';
  hiddenSearch: boolean = false;
  total: number = 0;
  filters: any = {};
  searchModel: any = {};

  searchActionv(searchData: any) {
    console.log('search action');
    this.products = searchData.res.products.docs;
    this.total = searchData.res.products.totalDocs;
    this.inputtext = searchData.searchModel.text;
    this.filters = searchData.res.filters;
    this.searchModel = searchData.searchModel;

    this.hiddenSearch = true;

    console.log('model');
    console.log(this.searchModel);
    console.log(this.products);
    console.log(this.hiddenSearch);
    window.scrollTo(0, 0);
  }

  goBack(data: any) {
    console.log('goback');
    this.products = [];
    this.hiddenSearch = false;
  }
}
