import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  public path;

  constructor(private _http: HttpClient) {
    this.path = environment.APIEndpoint;
  }

  submitSubscriptionForm(body): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.path + 'customer-public/form1/', body, {
      headers: headers,
    });
  }
}
