<app-header></app-header>
<app-slider [hiddenin]="hiddenSearch"></app-slider>
<app-search
  (searchAction)="searchActionv($event)"
  [hiddenin]="hiddenSearch"
></app-search>
<app-result
  [productsin]="products"
  [filtersin]="filters"
  [hiddenin]="hiddenSearch"
  [searchModelIn]="searchModel"
  [totalin]="total"
  [titlein]="inputtext"
  (goBack)="goBack($event)"
></app-result>
<app-tutorials></app-tutorials>
<app-blog></app-blog>

<app-footer></app-footer>
