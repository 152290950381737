<div class="modal-header" style="padding: 0; margin: 0">
  <div class="head-image-container">
    <img src="assets/img/skf-agro-newsletter-subscription.jpg" alt="My Image" />
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="closeModal()"
    style="
      position: absolute;
      right: 10px;
      top: 1em;
      font-size: 2em;
      background-color: transparent;
      border: none;
      color: white;
    "
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="mx-5 my-3">
  <form [formGroup]="form">
    <div class="form-group my-2">
      <label for="nombre">Nombre</label>
      <input
        type="text"
        class="form-control"
        id="nombre"
        formControlName="nombre"
        [ngClass]="{ 'is-invalid': hasError('nombre', 'required') }"
      />
      <!-- Display error message for 'required' error -->
      <div *ngIf="hasError('nombre', 'required')" class="text-danger">
        Nombre es requerido.
      </div>
    </div>
    <div class="form-group my-2">
      <label for="apellido">Apellido</label>
      <input
        type="text"
        class="form-control"
        id="apellido"
        formControlName="apellido"
        [ngClass]="{ 'is-invalid': hasError('apellido', 'required') }"
      />
      <!-- Display error message for 'required' error -->
      <div *ngIf="hasError('apellido', 'required')" class="text-danger">
        Apellido es requerido.
      </div>
    </div>
    <div class="form-group my-2">
      <label for="email">Email</label>
      <input
        type="email"
        class="form-control"
        id="email"
        formControlName="email"
        [ngClass]="{ 'is-invalid': hasError('email', 'required') }"
      />
      <!-- Display error message for 'required' error -->
      <div *ngIf="hasError('email', 'required')" class="text-danger">
        Email es requerido.
      </div>
      <!-- Display error message for 'email' format error -->
      <div *ngIf="hasError('email', 'email')" class="text-danger">
        Formato de email inválido.
      </div>
    </div>
    <div class="font-weight-light" style="font-size: 0.9em">
      Con el registro aceptás que te lleguen las comunicaciones y promociones
      SKF
    </div>
    <div class="d-grid gap-2 my-5">
      <button
        [disabled]="rateLimitingAlert"
        class="btn btn-primary"
        type="button"
        (click)="onSubmit()"
      >
        Enviar
      </button>
    </div>
    <div class="progress mb-2" *ngIf="rateLimitingAlert">
      <div
        class="progress-bar"
        [style.width.%]="progress"
        [attr.aria-valuenow]="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        background-color: lightgray;
        padding: 1em;
      "
      *ngIf="rateLimitingAlert"
    >
      <div style="width: 80%; user-select: none">Aguarde un momento</div>
    </div>
  </form>
</div>
