import { Component } from '@angular/core';
import { GlobalEventService } from 'src/services/global-event-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private globalEventService: GlobalEventService) {}

  openNewsletterModal() {
    this.globalEventService.emitEvent({
      type: 'OPEN_NEWSLETTER_SUBSCRIBE_MODAL',
    });
  }
}
