import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalEventService {
  public globalEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  emitEvent(data: any) {
    this.globalEventEmitter.emit(data);
  }
}
