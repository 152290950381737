import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output() searchAction: EventEmitter<any> = new EventEmitter<any>();
  hidden: boolean = false;

  hiddenCheckboxesAroInterior: boolean = true;
  hiddenCheckboxesAroExterior: boolean = true;
  hiddenCheckboxesSellos: boolean = true;
  hiddenCheckboxesFamily: boolean = true;

  mousein: boolean = false;

  searchModel: any = {
    text: '',
    family: '',
    designation: '',
    observations: '',
    aroint: [],
    aroext: [],
    sellos: [],
    dimentions: '',
    unit: '',
    option: 1,
  };

  arointOptions: string[] = [
    'Chavetado',
    'Cilíndrico',
    'Con manguito',
    'Cónico',
    'Cuadrado',
    'Hexagonal',
  ];

  arointOption: any[] = [];

  aroextOptions: string[] = ['Bombé', 'Recto'];

  aroextOption: any[] = [];
  sellosOptions: string[] = [
    'Doble labio',
    'Doble labio - Sin sello',
    'Doble labio - Simple labio',
    'Simple labio',
    'Simple labio - Doble labio',
    'Triple labio',
    'Triple labio - abierto',
    'Triple labio - Simple labio',
  ];

  sellosOption: any[] = [];

  familyOptions: string[] = [
    'Cadenas',
    'Crucetas',
    'Lubricación',
    'Piñones',
    'Retenes',
    'Rodamientos',
    'Soportes',
  ];

  familyOption: any[] = [];

  constructor(private _searchService: SearchService) {}

  ngOnInit(): void {
    this._searchService.getFilters().subscribe((res: any) => {
      this.familyOptions = res.filters.family;
      this.familyOptions = this.familyOptions.filter((value) => {
        if (value == null || value == '') return false;
        return true;
      });

      this.arointOptions = res.filters.arointeriors;
      this.arointOptions = this.arointOptions.filter((value) => {
        if (value == null || value == '') return false;
        return true;
      });

      this.aroextOptions = res.filters.aroexteriors;
      this.aroextOptions = this.aroextOptions.filter((value) => {
        if (value == null || value == '') return false;
        return true;
      });

      this.sellosOptions = res.filters.sellos;
      this.sellosOptions = this.sellosOptions.filter((value) => {
        if (value == null || value == '') return false;
        return true;
      });
    });
  }

  @Input()
  set hiddenin(hidden) {
    if (typeof hidden === 'undefined') {
      return;
    }
    console.log('hidden');
    console.log(hidden);
    this.hidden = hidden;
    if (this.hidden == false) this.clearInputs();
  }

  searchByText() {
    this._searchService
      .getByText(this.searchModel.text, 1, 20)
      .subscribe((res) => {
        console.log('emit');
        this.searchModel.option = 1;
        this.searchAction.emit({
          res: res,
          searchModel: this.searchModel,
        });
      });
  }
  reduceMultiselectModelForSearchPayload(model) {
    return model.reduce(
      (p, a) => ({
        ...p,
        [a]: true,
      }),
      {}
    );
  }
  @Output() popupSubscriberModal: EventEmitter<any> = new EventEmitter<any>();
  searchAdvanced() {
    this.hiddenAllCheckboxes();

    this.searchModel.family = this.reduceMultiselectModelForSearchPayload(
      this.familyOption
    );
    this.searchModel.aroint = this.reduceMultiselectModelForSearchPayload(
      this.arointOption
    );
    this.searchModel.aroext = this.reduceMultiselectModelForSearchPayload(
      this.aroextOption
    );
    this.searchModel.sellos = this.reduceMultiselectModelForSearchPayload(
      this.sellosOption
    );

    console.log(this.searchModel);

    this._searchService.advanced(this.searchModel, 1, 20).subscribe((res) => {
      console.log('emit');
      this.searchModel.option = 2;
      this.searchAction.emit({
        res: res,
        searchModel: this.searchModel,
      });
    });
  }

  hiddenAllCheckboxes() {
    this.hiddenCheckboxesAroInterior = true;
    this.hiddenCheckboxesAroExterior = true;
    this.hiddenCheckboxesSellos = true;
    this.hiddenCheckboxesFamily = true;
  }

  clearInputs() {
    this.searchModel.text = '';
    this.searchModel.family = '';
    this.searchModel.designation = '';
    this.searchModel.observations = '';
    this.searchModel.aroint = [];
    this.searchModel.aroext = [];
    this.searchModel.sellos = [];
    this.searchModel.dimentions = '';
    this.searchModel.unit = '';
    this.searchModel.option = 1;

    this.arointOption = [];
    this.aroextOption = [];
    this.familyOption = [];
  }
}
