<header>
  <div class="container-fluid">
    <div class="row">
      <div
        class="d-flex align-items-center py-3 bg-primary justify-content-between"
      >
        <div class="d-flex align-items-center">
          <div class="logo me-2">
            <a href="/" class="d-inline-block icono icono-skf-light"></a>
          </div>
          <div class="logo">
            <a href="#" class="d-inline-block icono icono-peer-primary"></a>
          </div>
        </div>
        <div
          class="d-flex flex-wrap align-items-end justify-content-end header-links"
        >
          <a
            href="https://www.skf.com/ar/products/agricola"
            class="text-white px-4"
            target="_blank"
            >BIBLIOGRAFÍA</a
          >
          <span
            (click)="openNewsletterModal()"
            class="text-white px-4"
            style="cursor: pointer"
            >SUSCRIBIR</span
          >
        </div>
      </div>
    </div>
  </div>
</header>
