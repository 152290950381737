<app-header></app-header>
<div class="container" style="margin-bottom: 4em">
  <div class="row align-items-center py-4 px-2">
    <div class="col-md-6">
      <h1>Blog</h1>
    </div>
    <div class="col-md-6 py-4">
      <input
        type="text"
        class="form-control"
        name="designation"
        placeholder="Buscar"
        [(ngModel)]="searchQuery"
        (input)="onSearchInputChange()"
        style="border: none; border-bottom: 1px lightgray solid"
      />
    </div>
  </div>
  <div class="container">
    <div class="row my-2">
      <div
        class="alert bg-light-grey"
        *ngIf="searchQuery && items.length === 0 && !loadingSearch && !loading"
      >
        No se han encontrado resultados
      </div>
      <div class="alert bg-primary text-white" *ngIf="loadingSearch || loading">
        Cargando
      </div>
      <div class="col-lg-4" *ngFor="let item of items">
        <a href="{{ item.href }}" target="_blank" style="text-decoration: none">
          <div class="card blog-entry my-3">
            <img
              src="{{ item.img }}"
              class="card-img-top"
              alt="{{ item.title }}"
            />
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">
                {{ item.description }}
              </p>
            </div>
          </div></a
        >
      </div>
      <button
        class="btn btn-block btn-default btn-outline-secondary my-5"
        *ngIf="loading && isMoreDocsAvailable()"
        (click)="getMoreDocs()"
        [disabled]="loading"
      >
        {{ loading ? "Cargando..." : "Ver mas" }}
      </button>
    </div>
  </div>
</div>

<app-footer></app-footer>
