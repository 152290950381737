import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public path;

  constructor(private _http: HttpClient) {
    this.path = environment.APIEndpoint;
  }

  getByText(text, page, limit): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(
      this.path + 'search/product/bytext/' + text + '/' + page + '/' + limit,
      { headers: headers }
    );
  }

  advanced(data, page, limit): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(
      this.path + 'search/product/advanced/' + page + '/' + limit,
      data,
      { headers: headers }
    );
  }

  getCarrousel() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.path + 'carrousel', { headers: headers });
  }

  getFilters() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.path + 'product/filters', { headers: headers });
  }
}
