import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  public path;

  constructor(private _http: HttpClient) {
    this.path = environment.APIEndpoint;
  }

  get(params?): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.path + 'blog-entry', {
      headers: headers,
      params,
    });
  }
  search(params?): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.path + 'blog-entry', {
      headers: headers,
      params,
    });
  }
}
