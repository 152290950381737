import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BlogService } from 'src/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    private _service: BlogService,
    private router: Router
  ) {}

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  items: [];

  ngOnInit(): void {
    this._service.get({ limit: 3 }).subscribe(
      (res) => {
        this.items = res.docs;
      },
      () => {}
    );
  }
  navigateTo(to) {
    this.router.navigate([to]);
  }
}
