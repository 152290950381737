<div class="slider" [hidden]="hidden">
  <div class="container-fluid">
    <div class="row">
      <swiper
        [config]="config"
        class="swiperSliderHome"
        style="max-height: 500px"
      >
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide" *ngFor="let slide of slideData">
            <a href="{{ slide.url }}" target="_blank" class="link-slide">
              <picture class="slide-home-img">
                <source
                  srcset="/assets/imgs/{{slide.imagepathMobile}}"
                  media="(max-width: 767px) and (orientation: portrait)"
                />

                <img
                  class="slide-home-img"
                  src="/assets/imgs/{{ slide.imagepath }}"
                />
              </picture>
            </a>
          </div>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>

        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </swiper>
    </div>
  </div>
</div>
