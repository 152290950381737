import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {}

  // Function to sanitize YouTube video URLs
  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  tutorials = [
    {
      id: 1,
      title: 'Introduccion a la plataforma',
      src: 'https://www.youtube.com/embed/B1rKtqIfwmw?si=R5NDfWtdpaYJRgxY?showinfo=0',
    },
    {
      id: 2,
      title: 'Banners en carrousel',
      src: 'https://www.youtube.com/embed/AeM20o6UUn8?si=oDUnZv5-XY01t_TP',
    },
    {
      id: 3,
      title: 'Busqueda de texto libre',
      src: 'https://www.youtube.com/embed/ieHdHAUXicI?si=Aa1rMEz7cxtXp2g4',
    },
    {
      id: 4,
      title: 'Búsqueda avanzada',
      src: 'https://www.youtube.com/embed/Ep95Wtbp3pg?si=-KtMmBmJ9DQex61W',
    },
  ].map((i) => ({ ...i, src: this.sanitizeUrl(i.src) }));

  ngOnInit(): void {}
}
