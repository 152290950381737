<div class="container" style="margin-bottom: 4em">
  <div class="pt-5 px-4">
    <h1>Blog AGRO SKF</h1>
  </div>
  <div class="container">
    <div class="row my-4">
      <div class="col-lg-4" *ngFor="let item of items">
        <a href="{{ item.href }}" target="_blank" style="text-decoration: none">
          <div class="card blog-entry">
            <img
              src="{{ item.img }}"
              class="card-img-top"
              alt="{{ item.title }}"
            />
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">
                {{ item.description }}
              </p>
            </div>
          </div></a
        >
      </div>
      <button
        (click)="navigateTo('/blog')"
        class="btn btn-block btn-default btn-outline-secondary my-5"
      >
        Ver mas
      </button>
    </div>
  </div>
</div>
