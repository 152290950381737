import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { ResultComponent } from './result/result.component';
import { SliderComponent } from './slider/slider.component';
import { HeaderComponent } from './header/header.component';
import {
  NgbPaginationModule,
  NgbModalModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { LandingModalSoporteComponent } from './modals/landing-modal-soporte.component';
import { TutorialsComponent } from './home/tutorials/tutorials.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlogComponent } from './home/blog/blog.component';
import { BlogFullComponent } from './blog/blog.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchComponent,
    ResultComponent,
    SliderComponent,
    HeaderComponent,
    FooterComponent,
    TutorialsComponent,
    LandingModalSoporteComponent,
    BlogComponent,
    BlogFullComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    FormsModule,
    HttpClientModule,
    NgbPaginationModule,
    NgbModalModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbModule,
    NgSelectModule,
  ],
  exports: [ReactiveFormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
